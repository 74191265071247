import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, IconButton, Drawer, List, ListItem, ListItemText, Box, CssBaseline, Container, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import logo from '../assets/logo.svg'
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

function withNavigation(Component) {
    return props => {
      let navigate = useNavigate();
      return <Component {...props} navigate={navigate} />;
    };
  }
  
class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileMenuOpen: false
        };
        this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
    }

    toggleMobileMenu() {
        this.setState({ mobileMenuOpen: !this.state.mobileMenuOpen });
    }
    handleNavigation = (path) => {
        this.props.navigate(path);
        window.scrollTo(0, 0);
      };

    render() {
        const { mobileMenuOpen } = this.state;

        return (
            <Box sx={{ backgroundColor:'transparent' }}  >
                <Helmet>
                    <title>Robotics Media</title>
                    <meta name="description" content="Robotics Media: Premier provider of IoT solutions, graphic design, and professional photography & videography services. Crafting innovative, customized digital experiences for businesses and corporate clients. Explore our services to elevate your brand's digital presence." />
                    <meta name="keywords" content="Robotics Media, IT solutions, graphic design, photography, videography, digital services, brand, innovate, impress, branding, bristol, kingswood, england, romania, galati" />
                </Helmet>

                <CssBaseline />
                <AppBar position="fixed" sx={{ background: 'rgba(49, 48, 48, 0.8)' }}>
                    <Container maxWidth='xl'>
                        <Toolbar>
                            <div style={{ flexGrow: 1, alignContent: 'flex-end', display: 'flex', maxHeight:'100px' }} >
                                <Button onClick={() => this.handleNavigation('/')} >
                                    <img src={logo} alt="Logo" className='App-logo' style={{ height: '100px' }} />
                                </Button>
                            </div>
                            <div className="desktop-view" style={{ alignItems: 'center', justifyContent: 'space-evenly' }} >
                                <Button  onClick={() => this.handleNavigation('/')} sx={{ textTransform: 'none', color: 'white', '&:hover': { color: 'red' } }}>
                                    <Typography variant='h6' align='center'>Home</Typography>
                                </Button>
                                <Button color="inherit" onClick={() => this.handleNavigation('/about')} sx={{ textTransform: 'none', color: 'white', '&:hover': { color: 'red' } }}>
                                    <Typography variant='h6' align='center'>About Us</Typography>
                                </Button>
                                {/* <Button color="inherit"  onClick={() => this.handleNavigation('/blog')} sx={{ textTransform: 'none', color: 'white', '&:hover': { color: 'red' } }}>
                                    <Typography variant='h6' align='center'>Blog</Typography>
                                </Button> */}
                                <Button color="inherit"  onClick={() => this.handleNavigation('/services')} sx={{ textTransform: 'none', color: 'white', '&:hover': { color: 'red' } }}>
                                    <Typography variant='h6' align='center'>Services</Typography>
                                </Button>
                                {/* <Button color="inherit" onClick={() => this.handleNavigation('/portfolio')} sx={{ textTransform: 'none', color: 'white', '&:hover': { color: 'red' } }}>
                                    <Typography variant='h6' align='center'>Portfolio</Typography>
                                </Button> */}
                                {/* <Button color="inherit" onClick={() => this.handleNavigation('/testimonials')} sx={{ textTransform: 'none', color: 'white', '&:hover': { color: 'red' } }}>
                                    <Typography variant='h6' align='center'>Testimonials</Typography>
                                </Button> */}
                                <Button color="inherit" onClick={() => this.handleNavigation('/contact')} sx={{ textTransform: 'none', color: 'white', '&:hover': { color: 'red' } }}>
                                    <Typography variant='h6' align='center'>Get in Touch</Typography>
                                </Button>
                            </div>
                            <div className="mobile-view">
                                <IconButton
                                    edge="end"
                                    color="inherit"
                                    aria-label="menu"
                                    width='40px'
                                    height='40px'
                                    onClick={this.toggleMobileMenu}
                                >
                                    <MenuIcon width='60px' height='60px' />
                                </IconButton>
                            <Drawer
                                anchor="right"
                                open={mobileMenuOpen}
                                onClose={this.toggleMobileMenu}
                                width='240'
                            >
                                <Container sx={{ height: '100%', background: 'rgba(19, 19, 19, 0.9)', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', color: 'white' }}>
                                    
                                    <List sx={{ textAlign: "center",  padding: "40px" }} >
                                        <Box height='50px'/>
                                        <ListItem button component={Link} to="/"    onClick={() => {this.toggleMobileMenu();window.scrollTo(0, 0);}}>
                                            <ListItemText primary={ <Typography variant="h6" style={{ color: '#FFFFFF' }}>Home</Typography>} />
                                        </ListItem>
                                        <ListItem button component={Link} to="/about" onClick={() => {this.toggleMobileMenu();window.scrollTo(0, 0);}}>
                                            <ListItemText primary={ <Typography variant="h6" style={{ color: '#FFFFFF' }}>About us</Typography>} />
                                        </ListItem>
                                        {/* <ListItem button component={Link} to="/blog"  onClick={() => {this.toggleMobileMenu();window.scrollTo(0, 0);}}>
                                            <ListItemText primary={ <Typography variant="h6" style={{ color: '#FFFFFF' }}>Blog</Typography>} />
                                        </ListItem> */}
                                        <ListItem button component={Link} to="/services"  onClick={() => {this.toggleMobileMenu();window.scrollTo(0, 0);}}>
                                            <ListItemText primary={ <Typography variant="h6" style={{ color: '#FFFFFF' }}>Services</Typography>} />
                                        </ListItem>
                                        {/* <ListItem button component={Link} to="/portfolio"  onClick={() => {this.toggleMobileMenu();window.scrollTo(0, 0);}}>
                                            <ListItemText primary={ <Typography variant="h6" style={{ color: '#FFFFFF' }}>Portfolio</Typography>} />
                                        </ListItem> */}
                                        {/* <ListItem button component={Link} to="/testimonials"  onClick={() => {this.toggleMobileMenu();window.scrollTo(0, 0);}}>
                                            <ListItemText primary={ <Typography variant="h6" style={{ color: '#FFFFFF' }}>Testimonials</Typography>} />
                                        </ListItem> */}
                                        <ListItem button component={Link} to="/contact" onClick={() => {this.toggleMobileMenu();window.scrollTo(0, 0);}}>
                                            <ListItemText primary={ <Typography variant="h6" style={{ color: '#FFFFFF' }}>Get in Touch</Typography>} />
                                        </ListItem>
                                    </List>
                                </Container>
                            </Drawer>
                        </div>
                    </Toolbar>
                </Container>
            </AppBar>
            </Box >
        );
    }
}

export default withNavigation(NavBar);
