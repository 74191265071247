import React, { Component } from 'react';
import { Paper, Typography, Button, Box, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import withRouter
import FadeInSection from '../useInView';

const data = [
    { title: 'IT Wizzards', text: 'Unleash the power of technology with our IT Wizards. From crafting cutting-edge software solutions to protecting your digital realm like superheroes, we’re your partners in the digital age.', target: 'target-1' },
    { title: 'Design Dreamers', text: 'Step into a world of creativity with our Design Dreamers. We breathe life into brands, with a touch of identity wizardry, graphic magic, and inventive charm. Let us make your brand unforgettable.', target: 'target-2' },
    { title: 'Media Masters', text: 'Experience captivating content with our Media Masters. Journey through stunning visuals, timeless videos, and seamless event coverage. We transform your ideas into media masterpieces.', target: 'target-3' },
];
export function withRouter(Component) {
    return function WrapperComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}
class ServicesComponent extends Component {
    handleNavigateAndScroll = (target) => {
        // Use navigate from props
        this.props.navigate('/services');

        setTimeout(() => {
            const element = document.getElementById(target);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, 0);
    };

    render() {
        return (
            <Container sx={{ width: '100%' }}>
                <FadeInSection>
                    <Typography className='fadeInLeftToRight' variant="code" fontSize='2.5rem' fontFamily='PT Mono' color='white' align="flex-start"> Our Services </Typography>

                </FadeInSection>
                <Box style={{ height: '50px' }} />
                <Box sx={{

                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' }, // Stack on small screens, row on medium and up
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: 8, // Add gap between items for all screen sizes
                }}>
                    {data.map((item) => (
                        <FadeInSection>
                            <Paper
                                key={item.title}
                                sx={{
                                    p: 2,
                                    bgcolor: 'rgba(0, 0, 0, 0.5)',
                                    borderRadius: '20px',
                                    border: '1px solid white',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    color: 'white',
                                    height: '500px',
                                    maxWidth: '250px',
                                    textAlign: 'center',
                                    textJustify: 'auto',
                                }}
                            >
                                <Typography variant="h5" fontSize='2rem' marginTop='20px' component="h4"  >{item.title}</Typography>

                                <Typography variant="code" className='code-element' >{item.text}</Typography>
                                <Button
                                    variant="contained"
                                    sx={{
                                        mt: 2,
                                        bgcolor: 'white',
                                        color: 'black',
                                        fontFamily: 'PT Mono, monospace',
                                        fontWeight: 'bold',
                                        fontSize: '1.2rem',
                                        width: '200px',
                                        height: '40px',
                                        borderRadius: '10px',
                                        '&:hover': {
                                            bgcolor: 'white',
                                            opacity: 0.9,
                                        },
                                    }}
                                    onClick={() => this.handleNavigateAndScroll(item.target)}
                                >
                                    EXPLORE MORE
                                </Button>
                            </Paper>
                        </FadeInSection>
                    ))}
                </Box>
            </Container>
        );
    }
}

export default withRouter(ServicesComponent);
