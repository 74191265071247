import React, { Component, lazy, Suspense } from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import axios from 'axios';
import backend from '../config';

// Lazy loading the PaperGenerator component
const LazyPaperGenerator = lazy(() => import('./BlogPostComponent'));

class Blog extends Component {
  state = {
    posts: [],
    filteredPosts: [],
    categories: [],
    filter: 'all',
  };

  componentDidMount() {
    const { filter } = this.props;

    // Fetch posts from the backend API
    axios.get(`${backend}/posts/`)
      .then(response => {
        const posts = response.data;
        let filteredPosts = posts;

        // Filter posts based on the category provided in the URL
        if (filter && filter !== 'all') {
          filteredPosts = posts.filter(post => post.category === filter);
        }

        const categories = [...new Set(posts.map(post => post.category))];
        this.setState({ posts, filteredPosts, categories, filter });
      })
      .catch(error => {
        console.error('Error fetching posts:', error);
      });
  }

  filterPosts = (category) => {
    if (category === 'all') {
      const { posts } = this.state;
      this.setState({ filteredPosts: posts, filter: 'all' });
      this.props.navigate('/blog');
    } else {
      const { posts } = this.state;
      const filteredPosts = posts.filter(post => post.category === category);
      this.setState({ filteredPosts, filter: category });
      this.props.navigate(`/blog/${category}`);
    }
  }

  render() {
    const { filteredPosts, categories, filter, posts } = this.state;
  
    return (
      <Container maxWidth='lg' maxHeight='100vh'>
        <Box sx={{ height: '150px' }} />
        <Typography variant="h2" color='white' align="flex-start">Blog</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 2, marginTop: '20px' }}>
          <Button
            variant={filter === 'all' ? 'contained' : 'outlined'}
            size="large"
            sx={{
              color: 'white',
              borderColor: filter === 'all' ? '' : 'white',
              backgroundColor: filter === 'all' ? 'black' : 'transparent',
              borderRadius: '20px',
              width: '200px',
              marginTop: '20px',
              fontFamily: 'Pt Mono',
              '&:hover': {
                backgroundColor: 'black',
                borderColor: 'white',
              }
            }}
            onClick={() => this.filterPosts('all')}
          >
            All
          </Button>
          {categories.map((category) => (
            <Button
              key={category}
              size="large"
              sx={{
                color: filter === category ? 'white' : 'white',
                borderColor: filter === category ? '' : 'white',
                backgroundColor: filter === category ? 'black' : 'transparent',
                borderRadius: '20px',
                width: '200px',
                marginTop: '20px',
                fontFamily: 'Pt Mono',
                '&:hover': {
                  backgroundColor: filter === category ? 'grey' : 'black',
                  color: 'white',
                  borderColor: 'white',
                }
              }}
              variant={filter === category ? 'contained' : 'outlined'}
              onClick={() => this.filterPosts(category)}
            >
              {category}
            </Button>
          ))}
        </Box>
        <br /><br />
        {posts.length === 0 ? (
          <Typography variant="body1" fontFamily='Pt Mono' height='40vh' color="white">No posts found.</Typography>
        ) : (
          <Suspense fallback={<div>Loading...</div>}>
            <LazyPaperGenerator key={posts.id} posts={filteredPosts} number={6} />
          </Suspense>
        )}
      </Container>
    )
  }
}

export default Blog;
