import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Blog from './Blog'; // Assuming the Blog component is in the same directory

const BlogWithNavigation = () => {
  const navigate = useNavigate();
  const { category } = useParams();
  
  return <Blog navigate={navigate} filter={category} />;
};

export default BlogWithNavigation;
