import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import BlogPost from './BlogPost'; // Assuming BlogPost is your class component

const BlogPostWrapper = () => {
  const params = useParams();
  const navigate = useNavigate();

  // Pass params and navigate as props to BlogPost
  return <BlogPost params={params} navigate={navigate} />;
};

export default BlogPostWrapper;
