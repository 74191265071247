import React, { Component } from 'react'
import { Box, Container } from '@mui/material'
import HomeComponent from './HomeComponent'
import TechComponent from '../tech-stack/TechComponent';
import ServicesComponent from '../services/ServicesComponent';

import { Helmet } from 'react-helmet-async';
import AboutComponent from '../about/AboutComponent';
import ContactComponent from '../contact/ContactComponent';
import LatestPosts from '../blog/LatestPost';

class Home extends Component {
    render() {
        return (
            <Container style={{ backgroundColor: 'transparent' }} >
                <Helmet>
                    <title>Robotics Media</title>
                    <meta name="description" content="Robotics Media: Premier provider of IoT solutions, graphic design, and professional photography & videography services. Crafting innovative, customized digital experiences for businesses and corporate clients. Explore our services to elevate your brand's digital presence." />
                    <meta name="keywords" content="Robotics Media, IT solutions, graphic design, photography, videography, digital services, brand, innovate, impress, branding, bristol, kingswood, england, romania, galati" />
                </Helmet>
                <Box style={{ height: '150px' }} />
                <HomeComponent />
                <Box style={{ height: '150px' }} />
                <AboutComponent/>
                <Box style={{ height: '150px' }} />
                <ServicesComponent />
                <Box style={{ height: '150px' }} />
                <LatestPosts/>
                <Box style={{ height: '150px' }} />
                <TechComponent />
                <Box style={{ height: '150px' }} />
                <ContactComponent/>
                <Box style={{ height: '50px' }} />
            </Container>
        )
    }
}

export default Home;