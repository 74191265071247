import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home/Home';
import Services from './pages/services/Services';
import Portfolio from './pages/portofolio/Portofolio';
import Testimonials from './pages/testimonials/Testimonials';
import AboutUs from './pages/about/AboutUs';
import NavBar from './pages/AppBar';

import './App.css'
import './index.css'
import { Box } from '@mui/material';
import Contact from './pages/contact/Contact';
import Footer from './pages/Footer';

import { HelmetProvider } from 'react-helmet-async';
import ScrollToTop from './pages/useScrollToTop';
import NotFoundPage from './pages/NotFoundPage';
import BlogPostWrapper from './pages/blog/BlogPostWrapper';
import BlogWithNavigation from './pages/blog/BlogWithNavigation';

class App extends Component {
  render() {
    return (
      <HelmetProvider>
        <Box className='app-container'>
          <Router>
            <ScrollToTop />
            <NavBar />
            <Routes>
              <Route path="/" exact element={<Home />} />
              <Route path="/blog" element={<BlogWithNavigation />} />
              <Route path="/blog/:category/:id" element={<BlogPostWrapper />} /> 
              <Route path="/blog/:category" element={<BlogWithNavigation />} /> 
              <Route path="/services" element={<Services />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/testimonials" element={<Testimonials />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/contact" element={<Contact />} />
              <Route path='/404' element={<NotFoundPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
            <Footer />
          </Router>
        </Box>
      </HelmetProvider>
    );
  }
}

export default App;

