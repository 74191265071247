import { Container, Typography } from '@mui/material'
import React, { Component } from 'react'

class HomeComponent extends Component {
    render() {
        return (
            <Container sx={{
                alignItems: 'flex-start',
                height: '70vh',
                width: '100%',
                backgroundColor: 'transparent',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                position: 'initial',
                zIndex: 1,
            }} >
                <Typography className='fadeInLeftToRight' variant='h1' fontSize='4rem' color='white' maxWidth='400px' > Shaping Tomorrow Together </Typography>
                <br/>
                <Typography className=' code-element fadeInRightToLeft ' variant='code' fontSize='1rem' component='h1' fontWeight="fontWeightBold" maxWidth='400px' color='white' >
                    Welcome to Robotics Media, where technology, design, and creativity converge.
                    Explore our world of innovation and expertise as we redefine the digital landscape.
                    Your journey to the future begins here.</Typography>
                <br />
                <Typography className='fadeBottomToTop' variant='h5' fontSize='2rem' color='white' >Tech. Creativity. Your Vision. Our Vision</Typography>
            </Container>
        )
    }
}
export default HomeComponent;