import React, { lazy, Component, Suspense } from 'react';
import { Box, Container, Typography, TextField, Button } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { Helmet } from 'react-helmet-async';
// import LatestPosts from './LatestPost';
import axios from 'axios';
import { Link } from 'react-router-dom';

import backend from '../config'

const LazyLatestPosts = lazy(() => import('./LatestPost'));

const textfieldStyle = {
    color: 'white',
    background: 'transparent',
    border: '2px solid white',
    borderRadius: '20px',
    fontFamily: 'Pt Mono, cursive',
    fontWeight: 'bold',
    marginBottom: '20px',
    alignItems: 'center',
    justifyContent: 'center'
};

const inputtextfieldStyle = {
    color: 'white',
    border: '2px solid white',
    borderRadius: '20px',
    fontFamily: 'Pt Mono, cursive',
    fontWeight: 'bold',
    alignItems: 'center',
    justifyContent: 'center'
};

const fieldStyles = {
    color: 'white',
    fontFamily: 'Pt Mono, cursive',
    fontWeight: 'bold',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2px'
};

class BlogPost extends Component {
    state = {
        post: null,
        isLoading: false,
        notFound: false,
        latestPosts: [],
        commentFormData: {
            name: '',
            email: '',
            comment: ''
        }
    };

    componentDidMount() {
        this.fetchPost();
        this.fetchLatestPosts();
    }

    fetchPost = async () => {
        const { params } = this.props;
        const postSlug = params.id;

        // Check if postSlug is defined
        if (!postSlug) {
            this.setState({ notFound: true, isLoading: false });
            return;
        }

        await axios.get(`${backend}/posts/${postSlug}`)
            .then(response => {
                this.setState({ post: response.data, isLoading: false });
                console.log(response.data);
            })
            .catch(error => {
                if (error.response && error.response.status === 404) {
                    this.props.history.push('/404');
                    this.setState({ notFound: true, isLoading: false });
                }
            });
    };


    fetchLatestPosts = () => {
        axios.get(`${backend}/posts/latest`)
            .then(response => {
                this.setState({ latestPosts: response.data.slice(0, 3) });
            })
            .catch(error => {
                console.error("Failed to fetch latest posts:", error);
            });
    };

    handleCommentSubmit = async (event) => {
        event.preventDefault();

        const { name, email, comment } = this.state.commentFormData;

        try {
            await axios.post('/posts/comment', { name, email, comment });
            console.log("Comment submitted successfully");
            // Optionally, you can update state or perform any other action after comment submission
        } catch (error) {
            console.error("Error submitting comment:", error);
            // Handle error (e.g., display error message to the user)
        }
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            commentFormData: {
                ...prevState.commentFormData,
                [name]: value
            }
        }));
    };

    render() {
        const { post, notFound } = this.state;

        if (notFound) return <div>Post not found.</div>;

        return (
            <Container maxWidth='lg'>
                {/* Helmet component for managing head tags */}
                <Helmet>
                    <title>{post ? `${post.title} - Blog Robotics Media` : 'Blog Title'}</title>
                    <meta name="description" content={post ? post.summary : "Default blog description"} />
                    <meta name="keywords" content={post ? post.keywords : "default, keywords, blog"} />
                </Helmet>
                <Box sx={{ height: '150px' }} />
                <Box>
                    {post && (
                        <>
                            <Typography variant="h2" color='white' gutterBottom>{post.title}</Typography>
                            <br /><br />
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', color: 'white' }}>
                                <Typography variant="h6" fontFamily='Pt Mono' fontSize='1rem' > Author: {post.author}</Typography>
                                <Typography variant="h6" fontFamily='Pt Mono' fontSize='1rem' >
                                    {post.date_published ? new Date(post.date_published).toLocaleDateString(undefined, {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    }) : 'Invalid Date'}
                                </Typography>
                                <Link to={`/blog/${post.category}`} style={{ textDecoration: 'underline', color: 'white' }}>
                                    <Typography variant="h6" color="white" fontFamily='Pt Mono' fontSize='1rem' sx={{ textAlign: 'flex-start', width: '90%' }}>
                                        {post.category}
                                    </Typography>
                                </Link>
                            </Box>
                            <br /><br />
                            <div style={{ color: 'white', fontFamily: 'Pt Mono' }}>
                                <ReactMarkdown>{post.content}</ReactMarkdown>
                            </div>
                        </>
                    )}

                    <br /><br />
                    <br /><br />
                    <Typography variant="h6" color="white">Leave a Comment</Typography>
                    <Box sx={{ width: '300px', margin: '0 auto' }}>
                        <form onSubmit={this.handleCommentSubmit}>
                            <TextField label="Name" variant="outlined"
                                InputProps={{ style: inputtextfieldStyle }}
                                InputLabelProps={{ style: fieldStyles }}
                                style={textfieldStyle}
                                fullWidth margin="normal" />
                            <TextField label="Comment" variant="outlined"
                                InputProps={{ style: inputtextfieldStyle }}
                                InputLabelProps={{ style: fieldStyles }}
                                style={textfieldStyle}
                                fullWidth multiline rows={4} margin="normal" />
                            <Button type="submit" variant="contained" sx={{ borderRadius: '20px', alignContent: 'center', alignItems: 'center', backgroundColor: 'black', color: 'white', fontFamily: 'Pt Mono', width: '100%', '&:hover': { backgroundColor: 'grey' } }} >
                                Post Comment
                            </Button>
                        </form>
                    </Box>


                    <Suspense fallback={<div>Loading latest posts...</div>}>
                        <Box mt={4}>
                            <Typography variant="h5" color="white">Latest Posts</Typography>
                            <LazyLatestPosts latestPosts={this.state.latestPosts} />
                        </Box>
                    </Suspense>
                </Box>
            </Container>
        );
    }
}

export default BlogPost;