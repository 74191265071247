import React, { Component } from 'react';
import { Container, Typography, Box, Link, Grid } from '@mui/material';

import { Helmet } from "react-helmet-async";

import about from '../../assets/about.jpg'
import journey from '../../assets/journey.jpg'
import expert from '../../assets/expert.jpg'
import philosophy from '../../assets/philosophy.jpg'
import comitment from '../../assets/comitment.jpg'

import FadeInSection from '../useInView';

class AboutUsPage extends Component {
  render() {

    const containerStyle = {
      backgroundImage: `url(${about})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    };

    return (
      <Container maxWidth="lg">
        <Helmet>
          <title>About Us - Robotics Media</title>
          <meta name="description" content="Learn more about Company Name, our mission, values, and the team." />
          <meta name="keywords" content="Robotics Media, IT solutions, graphic design, photography, videography, digital services, brand, innovate, impress, branding, bristol, kingswood, england, romania, galati" />
        </Helmet>
        <Box sx={{ height: '150px' }} />
        <FadeInSection>
        <Container  sx={{ height: '100%', alignContent: 'center', alignItems: 'center' }} style={containerStyle} >
          <Typography variant="h3" color='white' gutterBottom>
            About Us
          </Typography>
        </Container>
        <br /><br />
        <Typography className='code-element' variant="code" fontSize='1.5rem' color='white' gutterBottom>
          Welcome to Robotics Media - Your Gateway to Digital Transformation
        </Typography>
        <br /><br />
        <Typography className='code-element' variant='code' fontSize='1.2rem' color='white' paragraph>
          At Robotics Media, we're more than just a company; we're innovators, creators, and dreamers committed to redefining the digital landscape. With our headquarters nestled at the heart of technology's evolution, our mission is to harness the power of IT, design, and media to propel businesses into the future. Our website, <Link href="http://robotics-media.com" color="inherit">robotics-media.com</Link>, serves as a testament to our journey towards becoming industry leaders in delivering cutting-edge solutions that empower our clients.
        </Typography>
        </FadeInSection>
        <br />

        { /* Journey */}
        <FadeInSection>
          <Typography variant="h5" color='white' gutterBottom>
            Our Journey
          </Typography>
          <br />
          <Container sx={{ height: '100%', alignContent: 'center', alignItems: 'center' }}>
            <Grid container spacing={2} alignItems="center" justify="center" style={{ height: '100%' }}>
              <Grid item xs={12} md={6}>
                {/* Assuming you want to place an image here */}
                <img src={journey} alt="Our Journey" style={{ borderRadius: '20px', maxWidth: '100%', height: 'auto' }} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className='code-element' variant='body1' fontFamily='Pt Mono' fontSize='1.2rem' color='white' paragraph>
                  Founded on the principles of innovation, creativity, and excellence, Robotics Media has grown from a passionate team of IT Wizards, Design Dreamers, and Media Masters into a renowned digital powerhouse. Our journey has been fueled by a relentless pursuit of knowledge, a deep understanding of technology trends, and a commitment to delivering exceptional results.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </FadeInSection>


        <br /> <br />
        { /* Expertise */}
        <FadeInSection>
        <Typography variant="h5" color='white' justifyContent='center' alignItems='flex-end' gutterBottom>
          Our Expertise
        </Typography>
        <br />
        <Container sx={{ height: '100%', alignContent: 'center', alignItems: 'center' }}>
          <Grid container spacing={2} alignItems="center" justify="center" style={{ height: '100%' }}>
            <Grid item xs={12} md={6}>
              <Typography className='code-element' variant='code' fontSize='1.2rem' color='white' paragraph>
                Our expertise spans a comprehensive range of services designed to meet the diverse needs of our clients:
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <img src={expert} alt="Our Journey" style={{ borderRadius: '20px', maxWidth: '100%', height: 'auto' }} />

            </Grid>
          </Grid>
        </Container>
        </FadeInSection>

        <br /><br />
        <FadeInSection>
        <Box pl={2}>
          <Typography className='code-element' variant='code' fontSize='1.2rem' color='white' paragraph>- Technology Solutions: From app and site development to custom applications, automation, and AI, we offer a full spectrum of IT services that drive efficiency and growth.</Typography>
          <Typography className='code-element' variant='code' fontSize='1.2rem' color='white' paragraph>- Creative Design: Our Design Dreamers specialize in bringing brands to life with unparalleled creativity in brand identity, graphic design, UI/UX, and more.</Typography>
          <Typography className='code-element' variant='code' fontSize='1.2rem' color='white' paragraph>- Media Production: The Media Masters at Robotics Media transform ideas into captivating visuals, engaging videos, and comprehensive event coverage, ensuring your message resonates with your audience.</Typography>
        </Box>
        </FadeInSection>
        <br /><br />
        { /* Philosophy */}
        <FadeInSection>
        <Typography variant="h5" color='white' gutterBottom>
          Our Philosophy
        </Typography>
        <br />
        <Container sx={{ height: '100%', alignContent: 'center', alignItems: 'center' }}>
          <Grid container spacing={2} alignItems="center" justify="center" style={{ height: '100%' }}>
            <Grid item xs={12} md={6}>
              {/* Assuming you want to place an image here */}
              <img src={philosophy} alt="Our Journey" style={{ borderRadius: '20px', maxWidth: '100%', height: 'auto' }} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography className='code-element' variant='code' fontSize='1.2rem' color='white' paragraph>
                At Robotics Media, we believe that true innovation comes from collaboration. Our approach combines the latest technology with creative excellence to create solutions that are not only effective but also transformative. We're dedicated to staying at the forefront of digital trends, ensuring our clients benefit from the most advanced and efficient solutions available.
              </Typography>
            </Grid>
          </Grid>
        </Container>
        </FadeInSection>

        <br /> <br />
        { /* Commitment to You */}
        <FadeInSection>
        <Typography variant="h5" color='white' justifyContent='center' alignItems='flex-end' gutterBottom>
          Our Commitment to You
        </Typography>
        <br />
        <Container sx={{ height: '100%', alignContent: 'center', alignItems: 'center' }}>
          <Grid container spacing={2} alignItems="center" justify="center" style={{ height: '100%' }}>
            <Grid item xs={12} md={6}>
              <Typography className='code-element' variant='code' fontSize='1.2rem' color='white' paragraph>
                Your success is our success. As your partners in digital transformation, we're committed to understanding your unique challenges and objectives. Our team works tirelessly to deliver bespoke solutions that not only meet but exceed your expectations. With Robotics Media, you gain a partner equipped with the knowledge, skills, and passion to take your business to new heights.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <img src={comitment} alt="Our Journey" style={{ borderRadius: '20px', maxWidth: '100%', height: 'auto' }} />
            </Grid>
          </Grid>
        </Container>
        </FadeInSection>
        <br /><br />

        { /* Connect With Us */}
        <FadeInSection>
        <Typography variant="h5" color='white' justifyContent='center' alignItems='flex-end' gutterBottom>
          Connect With Us
        </Typography>
        <br />
        <Container sx={{ height: '100%', alignContent: 'center', alignItems: 'center' }}>
          <Grid container spacing={2} alignItems="center" justify="center" style={{ height: '100%' }}>
            <Grid item xs={12} md={6}>
              <Typography className='code-element' variant='code' fontSize='1.2rem' color='white' paragraph>
                Ready to start your digital transformation journey? Contact us today to learn how we can help your business thrive in the digital age.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography className='code-element' variant='code' fontSize='1rem' color='white' paragraph>
                Website: <Link href="http://robotics-media.com" color="inherit">robotics-media.com</Link><br />
                Email: <Link href="mailto:contact@robotics-media.com" color="inherit">contact@robotics-media.com</Link><br />
                Phone: <Link href="tel:+447949628183" color="inherit">07949 628 183</Link>
              </Typography>
            </Grid>
          </Grid>
        </Container>
        </FadeInSection>
        <br />
        <FadeInSection>
        <Typography className='code-element' variant='code' fontSize='1rem' color='white' paragraph>
          Join us at Robotics Media, where your digital future begins today.
        </Typography>
        </FadeInSection>
      </Container>
    );
  }
}

export default AboutUsPage;
