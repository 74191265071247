import React from 'react'

// Material UI mui.com
import Typography from "@mui/material/Typography"
import Container from '@mui/material/Container'
import { Grid } from '@mui/material'

const handleFooterClick = () => {
    window.open('https://robotics-media.com');
  };


function Copyright(props) {
    return (
        <Typography variant='subtitle2' color="white" fontFamily="Norton" align="center" {...props}>
            Website created by
            {' '}
            <Typography sx={{textDecoration: 'underline'}} variant='subtitle1' fontFamily='Norton' display="inline" cursor="pointer" >Robotics Media</Typography>
            {' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}


export default function Footer() {

    return (
        <Grid  paddingBottom="10vh" paddingTop="10vh" >
            <Container >
                <Copyright onClick={handleFooterClick} />
            </Container>
        </Grid>
    )
}