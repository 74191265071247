import React, { Component } from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FadeInSection from '../useInView';

export function withRouter(Component) {
    return function WrapperComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}

class AboutComponent extends Component {
    handleNavigationAbout = () => {
        this.props.navigate('/about');
        window.scrollTo(0, 0);
    }
    render() {

        return (
            <Container sx={{ alignContent:'center', justifyContent:'center',  width:'100%', padding: '40px' }}>
                <Box sx={{ width: '100%', textAlign: 'flex-start' }}>
                    <FadeInSection>
                        <Typography variant="code" fontSize='2.5rem' fontFamily='PT Mono' color='white' align="flex-start">
                            About Us
                        </Typography>
                    </FadeInSection>
                    <br/><br/>
                    <FadeInSection>
                        <Typography fontFamily='PT Mono' variant='h5' fontSize='1.3rem' color='white' gutterBottom>
                            At Robotics Media, we blend innovation with expertise to transform your digital landscape.
                        </Typography>
                    </FadeInSection>
                    <br/>
                    <FadeInSection>
                        <Typography fontFamily='PT Mono' fontSize='1.2rem' color='white' paragraph>
                            Dive into a world where technology meets creativity, and every solution is tailored to catapult your business into the future. From groundbreaking IT solutions and creative design to captivating media production, our team of IT Wizards, Design Dreamers, and Media Masters are dedicated to your success. Learn more about our journey, our passion, and how we're setting new standards in digital transformation.
                        </Typography>
                    </FadeInSection>
                </Box>
                <FadeInSection>
                    <Box sx={{ width: '100%', alignItems:'center', display:'flex', justifyContent:'center' }}>
                        <Button onClick={this.handleNavigationAbout} variant="contained" size="large" sx={{ backgroundColor: 'black', borderRadius: '20px', width: '200px', marginTop: '20px', fontFamily: 'Pt Mono' }}>
                            See More
                        </Button>
                    </Box>
                </FadeInSection>
            </Container>
        );
    }
}

export default withRouter(AboutComponent);