import React, { Component } from 'react'

class Testimonials extends Component {
  render() {
    return (
      <div>Testimonials</div>
    )
  }
}

export default Testimonials;