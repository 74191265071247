import { useInView } from 'react-intersection-observer';

const FadeInSection = (props) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2, // Adjusts how much of the item has to be in view to trigger animation
  });

  return (
    <div ref={ref} style={{ opacity: inView ? 1 : 0, transition: 'opacity 2s ease-out' }}>
      {props.children}
    </div>
  );
};

export default FadeInSection;