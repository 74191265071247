import { Box, Container, Typography } from '@mui/material'
import React, { Component } from 'react'

// icons
import {
    FaReact,
    FaNode,
    FaDocker,
    FaPython,
} from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io5";
import { BiLogoPostgresql } from "react-icons/bi";
import {
    SiAdobecreativecloud,
    SiAdobeaftereffects,
    SiAdobeillustrator,
    SiAdobeindesign,
    SiAdobephotoshop,
    SiAdobepremierepro,
    SiAdobexd,
    SiFlutter,
    SiVisualstudiocode,
    SiMysql
} from "react-icons/si";
import FadeInSection from '../useInView';

export default class TechComponent extends Component {
    render() {
        return (
            <FadeInSection>
                <Container sx={{ height: '100%', width: '100%', backgroundColor: 'transparent', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ width: '100%' }}>
                        <Typography className='fadeInLeftToRight' variant="code" fontSize='2.5rem' fontFamily='PT Mono' color='white' align="flex-start"> We master </Typography>
                    </Box>
                    <Box sx={{ height: '50px' }} />
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        gap: '20px', // Adjust the space between icons as needed
                        width: '90%',
                        height: '100%',
                        backgroundColor: 'transparent',
                        '& div': {
                            opacity: 0.5,
                            transition: 'opacity 0.3s',
                            position: 'relative', // Needed to position the span correctly
                            '&:hover': {
                                opacity: 1,
                                '& span': {
                                    visibility: 'visible', // Show the name on hover
                                    opacity: 1,
                                }
                            }
                        },
                        '& div span': {
                            visibility: 'hidden', // Hide the name by default
                            opacity: 0,
                            position: 'absolute',
                            bottom: '-20px', // Adjust based on your design
                            left: '50%',
                            transform: 'translateX(-50%)', // Center the span
                            whiteSpace: 'nowrap',
                            color: 'white', // Adjust the color as needed
                            transition: 'opacity 0.3s, visibility 0.3s',
                            fontFamily: 'Pt Mono',
                        }
                    }}>
                        <div><FaReact size={100} color='white' /><span>React</span></div>
                        <div><IoLogoJavascript size={100} color='white' /><span>JavaScript</span></div>
                        <div><FaNode size={100} color='white' /><span>Node.js</span></div>
                        <div><SiMysql size={100} color='white' /><span>MySQL</span></div>
                        <div><BiLogoPostgresql size={100} color='white' /><span>PostgreSQL</span></div>
                        <div><FaDocker size={100} color='white' /><span>Docker</span></div>
                        <div><FaPython size={100} color='white' /><span>Python</span></div>
                        <div><SiFlutter size={100} color='white' /><span>Flutter</span></div>
                        <div><SiAdobeaftereffects size={100} color='white' /><span>Adobe After Effects</span></div>
                        <div><SiAdobecreativecloud size={100} color='white' /><span>Adobe CC</span></div>
                        <div><SiAdobeillustrator size={100} color='white' /><span>Adobe Illustrator</span></div>
                        <div><SiAdobeindesign size={100} color='white' /><span>Adobe Indesign</span></div>
                        <div><SiAdobephotoshop size={100} color='white' /><span>Adobe Photoshop</span></div>
                        <div><SiAdobepremierepro size={100} color='white' /><span>Adobe Premier Pro</span></div>
                        <div><SiAdobexd size={100} color='white' /><span>Adobe Xd</span></div>
                        <div><SiVisualstudiocode size={100} color='white' /><span>vscode</span></div>
                    </Box>

                </Container>
            </FadeInSection>
        )
    }
}
