// Contact.js
import React, { Component } from 'react';
import { Container, Grid, Typography, TextField, Button, Box, Snackbar, Alert } from '@mui/material';
import { Facebook, Instagram, LinkedIn, YouTube, Twitter, WhatsApp } from '@mui/icons-material';
import FadeInSection from '../useInView';
import axios from 'axios';
import { Helmet } from "react-helmet-async";

const textfieldStyle = {
  color: 'white',
  background: 'transparent',
  border: '2px solid white',
  borderRadius: '20px',
  fontFamily: 'Pt Mono, cursive',
  fontWeight: 'bold',
  marginBottom: '20px',
  alignItems: 'center',
  justifyContent: 'center'
};

const inputtextfieldStyle = {
  color: 'white',
  border: '2px solid white',
  borderRadius: '20px',
  fontFamily: 'Pt Mono, cursive',
  fontWeight: 'bold',
  alignItems: 'center',
  justifyContent: 'center'
};

const fieldStyles = {
  color: 'white',
  fontFamily: 'Pt Mono, cursive',
  fontWeight: 'bold',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '2px'
};


class Contact extends Component {
  state = {
    fullName: '',
    email: '',
    phoneNumber: '',
    companyName: '',
    message: '',
    snackbarOpen: false,
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await axios.post('https://robotics-media.com/send-email', this.state); // Send form data to the /send-email endpoint
      console.log('Email sent successfully');
      this.setState({
        fullName: '',
        email: '',
        phoneNumber: '',
        companyName: '',
        message: '',
        snackbarOpen: true,
      });
    } catch (error) {
      console.error('Error sending email:', error);
      // Handle errors, such as displaying an error message to the user
    }
  };

  handleSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  render() {

    return (
      <Container sx={{ width: '100%', height: '100%' }}>
        <Helmet>
          <title>Contact Us - Robotics Media</title>
          <meta name="description" content="Get in Touch with us!" />
          <meta name="keywords" content="Robotics Media, IT solutions, graphic design, photography, videography, digital services, brand, innovate, impress, branding, bristol, kingswood, england, romania, galati" />
        </Helmet>
        <Box style={{ height: '150px' }} />
        <Box sx={{ width: '100%' }}>
          <FadeInSection>
            <Typography color='white' variant="h3" gutterBottom>
              Contact us
            </Typography>
          </FadeInSection>
          <Box sx={{ alignItems: 'center', justifyContent: 'center', width: '100%', flexDirection: 'column' }} >
            <Grid container spacing={4} sx={{ alignItems: 'center', justifyContent: 'center', height: '100%' }} >
              <Grid item xs={12} md={6} >
                <Box sx={{ alignItems: 'flex-start', justifyContent: 'center', width: '100%', flexDirection: 'column' }} >
                  <FadeInSection>
                    <Typography color='white' fontFamily='Pt Mono' variant="h6" textAlign='flex-start' gutterBottom>
                      Get in touch and let’s make things happen!
                    </Typography>
                    <Box sx={{ height: '150px' }} />
                    <Typography color='white' fontFamily='Pt Mono' variant="body1">
                      <a href="tel:+447949628183" style={{ color: 'inherit', textDecoration: 'none', cursor: 'pointer' }} onMouseOver={(e) => e.target.style.textDecoration = 'underline'} onMouseOut={(e) => e.target.style.textDecoration = 'none'}>+44 7949 628 183</a>
                    </Typography>
                    <Typography color='white' fontFamily='Pt Mono' variant="body1">
                      <a href="mailto:contact@robotics-media.com" style={{ color: 'inherit', textDecoration: 'none', cursor: 'pointer' }} onMouseOver={(e) => e.target.style.textDecoration = 'underline'} onMouseOut={(e) => e.target.style.textDecoration = 'none'}>contact@robotics-media.com</a>
                    </Typography>
                    <Box alignItems='flex-start' width='80%' mt={2}>
                      <a href="https://www.facebook.com/RoboticsMediaLTD" target="_blank" rel="noopener noreferrer" style={{ marginRight: '8px' }}>
                        <Facebook style={{ width: '50px', height: '50px', cursor: 'pointer', color: 'white' }} />
                      </a>
                      <a href="https://www.instagram.com/roboticsmedia/" target="_blank" rel="noopener noreferrer" style={{ marginRight: '8px' }}>
                        <Instagram style={{ width: '50px', height: '50px', cursor: 'pointer', color: 'white' }} />
                      </a>
                      <a href="https://api.whatsapp.com/send?phone=447949628183" target="_blank" rel="noopener noreferrer" style={{ marginRight: '8px' }}>
                        <WhatsApp style={{ width: '50px', height: '50px', cursor: 'pointer', color: 'white' }} />
                      </a>
                      <a href="https://www.linkedin.com/in/YourProfile" target="_blank" rel="noopener noreferrer" style={{ marginRight: '8px' }}>
                        <LinkedIn style={{ width: '50px', height: '50px', cursor: 'pointer', color: 'white' }} />
                      </a>
                      <a href="https://www.youtube.com/channel/UCWoVOLnvt0rReON-qYmVEeA" target="_blank" rel="noopener noreferrer" style={{ marginRight: '8px' }}>
                        <YouTube style={{ width: '50px', height: '50px', cursor: 'pointer', color: 'white' }} />
                      </a>
                      <a href="https://twitter.com/robotics_mediaU" target="_blank" rel="noopener noreferrer">
                        <Twitter style={{ width: '50px', height: '50px', cursor: 'pointer', color: 'white' }} />
                      </a>
                    </Box>
                  </FadeInSection>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} textAlign='center'>
                <FadeInSection>
                  <form onSubmit={this.handleSubmit}>
                    <TextField
                      fullWidth
                      required
                      autoComplete
                      variant="outlined"
                      name="fullName"
                      label="Full Name"
                      value={this.state.fullName}
                      onChange={this.handleChange}
                      InputProps={{ style: inputtextfieldStyle }}
                      InputLabelProps={{ style: fieldStyles }}
                      style={textfieldStyle}
                    />
                    <TextField
                      fullWidth
                      required
                      autoComplete
                      variant="outlined"
                      name="email"
                      label="Email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      InputProps={{ style: inputtextfieldStyle }}
                      InputLabelProps={{ style: fieldStyles }}
                      style={textfieldStyle}
                    />
                    <TextField
                      fullWidth
                      required
                      autoComplete
                      variant="outlined"
                      name="phoneNumber"
                      label="Phone"
                      value={this.state.phoneNumber}
                      onChange={this.handleChange}
                      InputProps={{ style: inputtextfieldStyle }}
                      InputLabelProps={{ style: fieldStyles }}
                      style={textfieldStyle}
                    />
                    <TextField
                      fullWidth
                      autoComplete
                      variant="outlined"
                      name="companyName"
                      label="Company Name"
                      value={this.state.companyName}
                      onChange={this.handleChange}
                      InputProps={{ style: inputtextfieldStyle }}
                      InputLabelProps={{ style: fieldStyles }}
                      style={textfieldStyle}
                    />
                    <TextField
                      fullWidth
                      required
                      autoComplete
                      variant="outlined"
                      name="message"
                      label="Share your thoughts, questions, or ideas with us."
                      multiline
                      rows={4}
                      value={this.state.message}
                      onChange={this.handleChange}
                      InputProps={{ style: inputtextfieldStyle }}
                      InputLabelProps={{ style: fieldStyles }}
                      style={textfieldStyle}
                    />
                    <Button type="submit" variant="contained" sx={{ borderRadius: '20px', alignContent: 'center', alignItems: 'center', backgroundColor: 'black', color: 'white', fontFamily: 'Pt Mono', width: '200px', '&:hover': { backgroundColor: 'grey' } }}>
                      Get in Touch
                    </Button>
                    <Snackbar
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      open={this.state.snackbarOpen}
                      autoHideDuration={6000}
                      onClose={this.handleSnackbarClose}
                      message="Email sent successfully!"
                    >
                      <Alert
                        onClose={this.handleSnackbarClose}
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%', fontFamily: 'Pt Mono' }}
                      >
                        Email sent successfully! We will get back to you as soon as possible.
                      </Alert>
                    </Snackbar>
                  </form>
                </FadeInSection>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box sx={{ height: '20vh' }} />
      </Container>
    );
  }
}

export default Contact;
