// pages/NotFoundPage.js or wherever you prefer to keep your components
import React from 'react';
import { Box, Typography, Container, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const NotFoundPage = () => {
    const navigate = useNavigate();

    return (
        <Container sx={{ height: '80vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
            <Helmet>
                <title>404 - Robotics Media</title>
                <meta name="description" content="Robotics Media: Premier provider of IoT solutions, graphic design, and professional photography & videography services. Crafting innovative, customized digital experiences for businesses and corporate clients. Explore our services to elevate your brand's digital presence." />
                <meta name="keywords" content="Robotics Media, IT solutions, graphic design, photography, videography, digital services, brand, innovate, impress, branding, bristol, kingswood, england, romania, galati" />
            </Helmet>
            <Box sx={{ height: '150px' }} />
            <Typography fontFamily='Pt Mono' color='white' variant="h4" gutterBottom>
                404 - Page Not Found
            </Typography>
            <Typography color='white' fontFamily='Pt Mono' variant="subtitle1" gutterBottom>
                The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
            </Typography>
            <Button variant="contained" color="primary" onClick={() => navigate('/')}>
                Go to Home
            </Button>
        </Container>
    );
};

export default NotFoundPage;
