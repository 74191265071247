import React, { Component } from 'react';
import { Card, CardActionArea, CardMedia, CardContent, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

class LatestPosts extends Component {
    handleReload = () => {
        window.location.reload();
    };

    render() {
        const { latestPosts } = this.props;

        // Check if latestPosts is undefined or null
        if (!latestPosts) {
            return null; // Or you can render a loading spinner or message here
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', flexWrap: 'wrap', justifyContent: 'center' }}>
                {latestPosts.map((post) => (
                    <Card key={post.id} sx={{ width: 300, borderRadius: '20px' }}>
                        <CardActionArea component={Link} to={`/blog/${post.category}/${post.slug}`} onClick={this.handleReload}>
                            <CardMedia
                                component="img"
                                height="140"
                                image={post.image}
                                alt={post.title}
                            />
                            <CardContent>
                                <Typography  variant="h5" fontFamily='Pt Mono' color='white' fontSize='1rem' component="div">
                                    {post.title}
                                </Typography>
                                <Typography  variant="h5" fontFamily='Pt Mono' color='white' fontSize='1rem' component="div">
                                    {post.category}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                ))
                }
            </div>
        );
    }
}

export default LatestPosts;
